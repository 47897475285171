@import '~bootstrap/scss/bootstrap';

@font-face{
    font-family: 'bree';
    src: url('./fonts/Bree-Regular/BreeSerif-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face{
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face{
    font-family: 'corinthia';
    src: url('./fonts/Corinthia/Corinthia-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

$font-logo: 'bree';
$font: 'Montserrat';
$font-courbe: 'corinthia';
$color-font: #242424;
$color-nav: #333333;
$color-font-clair: #fff9decc;
$color-background: #FFF9DE;
$color-tres-fonce: #043a1c;
$color-fonce: #5BA532;
$color-clair: #95C11F;
$color-tres-clair: #96c11f45;
$color-secondary-fonce: #F39322;
$color-secondary-clair: #F9B459;
$color-secondary-tres-clair: #F9B45933;

body{
    color: $color-font;
    background-color: $color-background;
    font-family: $font;
    font-size: 16px;
    margin-top: 127px;
    padding-bottom: 0px;
}

h1, h2, h3, .btn{
    font-family: $font-logo, $font;
}
h1{
    font-size: 30px;
}
h2{
    font-size: 25px;
}
h3{
    font-size: 22px;
    margin-bottom: 1rem;
}
h4{
    font-size: 20px;
}  
h5{
    font-weight: 500;
    font-size: 18px;
}
section{
    margin-bottom: 50px;
    padding: 0px;
}
@media (min-width: 992px) {
    section{
        margin-bottom: 100px;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    h1{
        font-size: 35px;
    }
    h2{
        font-size: 30px;
    }
    h3{
        font-size: 25px;
    }
    h4{
        font-size: 22px;
    }
    h5{
        font-size: 20px;
    }
}

.ombre{
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

main {
    min-height: 50vh;
    padding: 0px !important;
    }

span {
    font-weight: 600;
}

.petit{
    font-size: 1rem;
}

a{
    text-decoration: none !important;
}

.font{
    font-family: $font;
}

/* Les couleurs nutrition */
.bg-fonce{
    background-color: $color-fonce;
}
.bg-clair{
    background-color: $color-clair;
}
.bg-tres-clair{
    background-color: $color-tres-clair;
}
.bg-principal{
    background-color: $color-background;
}
.bg-secondary-fonce{
    background-color:$color-secondary-fonce !important;
}
.bg-secondary-clair{
    background-color:$color-secondary-clair !important;
}
.bg-secondary-tres-clair{
    background-color: $color-secondary-tres-clair;
}

.btn a{
    color: $color-font-clair !important;
    text-decoration: none !important;
}

.btn{
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 1px !important;
}
.btn-fonce{
    background-color: $color-fonce;
    color: $color-font-clair;
}
.btn-fonce a:hover, .text-fonce{
    background-color: $color-clair !important;
    color: $color-fonce !important;
}
.btn-orange a:hover{
    color: $color-font-clair !important;
}
.btn-fonce:hover{
    color: $color-fonce !important;
    background-color: $color-font-clair;
    border: $color-fonce 2px solid;
}
.btn-orange{
    color: $color-font-clair !important;
    background-color: $color-secondary-fonce;
}
.btn-orange-clair{
    color: $color-font-clair !important;
    background-color: $color-secondary-clair;
}
.btn-orange:hover{
    color:$color-secondary-fonce !important;
    background-color: $color-font-clair;
    border: $color-secondary-fonce 2px solid;
}
.btn-clair{
    background-color: $color-background;
    border: 1px solid $color-background;
    color: $color-tres-fonce;
    border-radius: 2px;
}
.btn-clair:hover{
    color: $color-fonce;
    background-color: $color-background;
    border: $color-background 2px solid;
}
.btn-red{
    background-color: red;
    color: $color-secondary-clair
}
.btn-red:hover{
    color:$color-secondary-fonce;
    background-color: $color-font-clair;
    border: $color-secondary-fonce 2px solid;
}

.text-orange{
    color: $color-secondary-fonce;
}
.text-vert{
    color: $color-fonce;
}
.text-clair{
    color: $color-font-clair;
}

/* Les navigations*/
.navbar{
    background-color: $color-background;
    padding: 1px !important;
    position: fixed;
    // width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index:1;
}
.nav-link{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: $color-nav !important;
    font-family: $font-logo;
}
.nav-link:hover{
    transform: scale(1.15);
}
.active{
    color: $color-fonce !important;
    font-weight: 500;
}
.moncompte{
    background-color: $color-clair !important;
    color: $color-font-clair !important;
    border-radius: 1px;
}
.sidebar{
    padding: 0 !important;
}

.bas {
    position: fixed;
    bottom: 0;
    background-color: $color-tres-clair;
    width: 100%;
    margin: auto;
    z-index:1;
}
// @media (max-width: 768px){
//     .bas{
//         max-height: 120px;
//         max-width: 90%;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }
// }

// Les images
.logoNav{
    max-width: 80px;
    margin: 2px;
}

.logo{
    max-width: 80px;
    // margin: 10px;
}
@media (min-width: 768px) and (max-width: 992px){
    .logo{
    max-width: 110px;
    }
    .logoNav{
        max-width: 100px;
        margin: 2px;
    }
}
@media (min-width: 992px) {
    .logo{
    max-width: 150px;
    }
    .logoNav{
        max-width: 120px;
        margin: 2px;
    }
}
.biglogo{
    max-width: 240px;
    margin: 20px;
}

.logo-coach{
    max-width: 50px;
    margin: 10px;
}

.picto{
    max-width: 20px;
}
.icone{
    max-height: 100px;
}
@media (min-width: 992px) {
    .icone{
        max-height: 120px;
    }
}

/* Les superpositions d'image / texte */
.imgparallalax{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 75vh;
    background-attachment: fixed;
    // Position de transbox à l'intérieur
    display: flex;
    justify-content: start;
    align-items: end;
}
@media (max-width: 991px){
    .imgparallalax{
        background-size: cover;
        min-height: 75vh;
    }
}
.imgAccueil{
    background-image:url("/public/images/accueilphoto.webp");
}
.imgLivre{
    background-image:url("/public/images/livreentete.webp");
}
.imgCoach{
    background-image:url("/public/images/entetecoaching.webp");
}
.imgNewsletter{
    background-image:url("/public/images/agenda.webp");
}
.imgAPropos{
    background-position: top;
    background-size: cover;
    min-height: 85vh;
    background-image: url("/public/images/photoCK-debout.jpg");
}
@media (max-width: 991px){
    .imgAPropos{
        background-position: top;
        background-size: auto;
    }
}

.margeInterne{
    margin-left: 4%;
    margin-right: 4%
}
.transbox {
    padding: 3% 5%;
    margin: 5%;
    background-color: $color-font-clair;
    min-height: 50%;
    border-radius: 25px;
    font-weight: 500;
    color: black;
}
.transbox h1{
    margin-left: 0;
    padding-left: 0;
}
div .transbox .btn {
    font-weight: 400;
    border-radius: 1px !important;
}


/* Les encarts présentant les 3 services, par défaut pour mobile */
.encart, .encart4{
    color: $color-font-clair;
    border-radius: 25px;
    background-color: $color-fonce;
    padding: 15px;
    margin: auto;
    width: 80%;
    margin-bottom: 25px;
}

.survolOmbre:hover, .encart:hover, .encart4:hover{
    border-radius: 25px;
    box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.25); 
}

@media (max-width: 991px){
    .encart{
        padding: 15px;
        margin: auto;
        width: 80%;
        margin-bottom: 25px;
        min-height: 370px;
    }
}
@media (min-width: 992px) and (max-width: 1200px){
    .encart{
        padding: 15px;
        margin: auto;
        margin-bottom: 20px;
        min-height: 540px;
    }
}
@media (min-width: 1200px) and (max-width: 1400px){
    .encart{
        padding: 15px;
        margin: 5px;
        width: 100%;
        min-height: 540px;
    }
}
@media (min-width: 1401px){
    .encart{
        padding: 25px;
        margin: 20px;
        width: 100%;
        min-height: 525px;
    }
}
@media (max-width: 991px){
    .encart4{
        padding: 15px;
        margin: auto;
        width: 80%;
        margin-bottom: 25px;
        min-height: 370px;
    }
}
@media (min-width: 992px) and (max-width: 1200px){
    .encart4{
        padding: 15px;
        // margin: 50px;
        margin-bottom: 20px;
        width: 100%;
        min-height: 450px;
    }
}
@media (min-width: 1200px) and (max-width: 1400px){
    .encart4{
        padding: 15px;
        margin: 1px;
        width: 100%;
        min-height: 540px;
    }
    .encart4 h3{
        min-height: 60px;
    }
    .encart4 h5{
        min-height: 100px;
    }
}
@media (min-width: 1401px){
    .encart4{
        padding: 25px;
        margin: 20px;
        width: 100%;
        min-height: 525px;
    }
    .encart4 h3{
        min-height: 60px;
    }
    .encart4 h5{
        min-height: 100px;
    }
}


/* Les photos des 4 services de l'index */
.servicephoto{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.servicephoto img, .entete img{
    max-width: 420px;
    width: 100%;
    margin-bottom: 20px;
}
.services{
    padding: 10px;
    margin-bottom: 30px;
}
@media (min-width: 992px) {
    .services{
        padding: 20px;
        margin-bottom: 100px;
    }
}
.entete{
    text-align: center;
}

/* Les RS */
.rs img{
    margin-bottom: 25px;
    max-width: 251px;
}

/* Les encarts présentant les tarifs coaching */
.tarif{
    margin-top: 0px;
    border-radius: 0px 0px 25px 25px;
    background-color: $color-tres-clair;
    padding: 15px;
    text-align: center;
    margin-bottom: 25px;
    border: solid 2px $color-tres-clair;
    min-height: 350px;
}
.tarif2{
    background-color: $color-background;
    border: solid 2px $color-fonce;
    margin-bottom: 25px;
    margin-top: 0px;
    border-radius: 0px 0px 25px 25px;
    padding: 15px;
    text-align: center;
    min-height: 350px;
}
.tarifLivre{
    background-color: $color-background;
    border: solid 2px $color-fonce;
    border-radius: 25px;
    padding: 15px;
    text-align: center;
    margin-bottom: 25px;
}
.forfait{
    background-color: $color-clair;
    border-radius: 25px 25px 0px 0px;
    padding: 15px;
    margin-bottom: 0px;
}
.forfait2{
    background-color: $color-fonce;
    border-radius: 25px 25px 0px 0px;
    padding: 15px;
    margin-bottom: 0px;
}
@media (min-width: 768px) and (max-width: 992px) {
    .tarifLivre, .forfait, .forfait2, .tarif, .tarif2{
        margin-left: 50px;
        margin-right: 50px;
    }
}
@media (max-width: 768px) {
    .tarifLivre, .forfait, .forfait2, .tarif, .tarif2{
        margin-left: 5px;
        margin-right: 5px;
    }
}

/*Les formulaires */
form{
    margin-bottom: 20px;
}
label{
    color: $color-fonce;
    font-weight: 600;
    margin-right: 5px;
}
.souslabel label{
    color: $color-font;
    margin-left: 10px;
    font-weight: 400;
}

/*Le tableau des utilisateurs */
thead tr{
    background-color: $color-fonce;
    color: $color-font-clair;
}
td a{
    color: $color-secondary-fonce;
    text-decoration: none;
    font-weight: 700;
}
td a:hover{
    color: $color-fonce;
    cursor: help;
}

/* Les alertes */

.alert{
    padding: 10px;
    color: $color-font-clair;
    text-align: center;
    /*Par défaut Bootstrap met une border à ses classes alertes, je peux les retirer et personnaliser mon CSS */
    /* margin: 0;
    border: none;
    border-radius: 0; */
}
.alert-success{
        background-color: $color-fonce;
}
.alert-info{
        background-color: red;
} 

/*Le calendrier */

#calendar{
    max-width: 1100px;
    margin: 40px auto;
}

/*la date d'accueil account */
.calendar {
    position: relative;
    width: 250px;
    background: $color-font-clair;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    text-transform: uppercase;
}
.calendar #dayName {
    padding: 5px 10px;
    background: $color-fonce;
    color: $color-font-clair;
}


/*Style LIRE LA SUITE*/
.texte-cache1, .texte-cache2, .texte-cache3 { 
    // margin: 50px 200px 5px 200px;
    position: relative;
    max-height: 100px; /*Hauteur du texte visible avant le clic*/
    overflow: hidden; /*On cache tout ce qui dépasse des 100px*/
    transition: max-height 0.5s ease; /* Transition CSS entre l'ouverture et la fermeture*/
}
/*Style du module LIRE LA SUITE lorsqu'il est ouvert*/
.texte-cache1.ouvert, .texte-cache2.ouvert, .texte-cache3.ouvert {
    max-height: 1000vh; /* On lui définit une hauteur max de tout l'écran si nécessaire pour qu'il affiche tout son contenu*/
}
/*On stylise l'effet de flou lorsque le module TEXTE est fermé, donc il n'a pas la classe "ouvert"*/
.texte-cache1:not(.ouvert)::after, .texte-cache2:not(.ouvert)::after, .texte-cache3:not(.ouvert)::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); /*Couleur de notre effet*/
}
// Style de texte courbé
.font-courbe{
    font-family: $font-courbe;
    font-size: 40px;
    font-weight: 400!important;
}
@media (min-width: 992px) {
    .font-courbe{
        font-size: 50px;
    }
}

.titreLien a{
    color: $color-fonce !important;
    text-decoration: none !important;
}

.hauteur{
    margin-bottom: 5px;
}
@media (min-width: 768px) {
    .border-right{
        border-right: 1px $color-nav solid;
    }
    .hauteur{
        margin-bottom: 30px;
        // min-height: 50px;
    }
}

// Carousel flèche

// .carousel-control-prev-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23F39322' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
// }
// .carousel-control-next-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23F39322' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
// }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 50px;
  width: 30px;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 40px;
  color: #F39322;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 40px;
  color: #F39322;
}

// Qui, quoi comment
@media (min-width: 768px) {
    .quiquoicomment{
        height: 100%;
    }
}

//Jauge / gauge
.gauge {
    // display: block;
    // float: left;
    max-width: 350px;
    margin: auto;
}

// defilement horizeontal en version mobile
@media (max-width: 992px) {
    .defilement{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
    }
}